.dataRange {
    height: 40px;
    width: 250px;
    border-radius: 12px;
    border-color: #7F7F84;
}

.dataRange:hover {
    border-color: #5222D0;
    color: #5222D0 !important;
} 

.ant-picker-focused {
    border-color: #5222D0;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.ant-picker-dropdown-range {
    z-index: 10000;
}

.ant-picker-focused .ant-picker-input>input {
    color: #5222D0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}

.ant-picker-focused .ant-picker-separator {
    color: #5222D0;
}

.dataRange:hover .ant-picker-separator {
    color: #5222D0;
}


.ant-picker .ant-picker-input,
.ant-picker.ant-picker-range {
    display: flex;
    flex-direction: row;
}

.ant-picker-suffix {
    order: -1;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
    margin-left: 0;
    margin-right: 2px;
}


.ant-picker-panel-container {
    border-radius: 12px;
}

.ant-picker-input>input {
    color: #7F7F84;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}

.dataRange:hover .ant-picker-input>input {
    color: #5222D0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}


.ant-picker-range .ant-picker-active-bar {
    background: #5222D0;
}

.ant-picker-header-view button:hover {
    color: #5222D0;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after {
    border-top: none;
    border-bottom: none;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
    background: transparent;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after {
    border-top: none;
    border-bottom: none;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after {
    border-left: none;
}

tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after {
    border-right: none;
}

tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after {
    border-right: none;
}

tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after {
    border-left: none;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background:rgba(82, 34, 208, 0.24);
    height: 36px;
    margin-left: 8px;
    width: 36px;
    border-radius: 8px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background: #5222D0;
    border-radius: 8px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
 border: 1px solid #5222D0;
 border-radius: 8px;
}


.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #5222D0;
    border-radius: 8px !important;
}
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background:rgba(82, 34, 208, 0.24);
    height: 36px;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
    background: rgba(82, 34, 208, 0.4);
    height: 36px;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before {
    background-color: rgba(82, 34, 208, 0.24);
    height: 36px;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background-color: rgba(82, 34, 208, 0.24);
    height: 36px;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
    background-color: rgba(82, 34, 208, 0.24);
    height: 36px;
    margin-right: 8px;
    width: 36px;
    border-radius: 8px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background-color: transparent;
    height: 36px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
    background-color: transparent;
    height: 36px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before {
    background-color: transparent;
    height: 36px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before {
    background-color: transparent;
    height: 36px;
    
}

.ant-picker-header-view {
    order: -1;
}

  .ant-picker-header-super-prev-btn {
    display: none !important;
  }

  .ant-picker-header-super-next-btn {
    display: none !important;
  }

  .ant-picker-header-view {
    margin-left: 25px;
  }