.modal {
	overflow-y: auto;
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: 0.5s;
}

.modal.active-modal {
	opacity: 1;
	pointer-events: all;
}

.modal-content {
  width: 1080px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 24px 40px; 
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  transform: scale(0.5);
  transition: 0.3s all;
}


.modal-content.active-modal {
	height: 80vh;
	transform: scale(1);
	background: #fff;
	opacity: 1;
}

@media screen and (max-width: 1200px) {
	.modal-content {
		width: 600px;
	}
}
