
.emptyProgressBar {
  background-color: #EB4D3D;
  border-radius: 2px;
  height: 4px;
  overflow: hidden;
  position: relative;
}
.fillingProgressBar {
  background-image: linear-gradient(to right, #65C466, #65C466);
  border-radius: 2px;
  height: 100%;
  width: 100%;
  position: absolute;
}
.mainProgressBarDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  margin-top: 7px;
  padding-left: 18px;
	padding-right: 20px;
}